#leadershipBGSVGTop {
	margin-top: -$gutter*7;
	margin-bottom: -2px;
}

#leadershipBGSVGBottom {
	margin-top: -$gutter*7;
	margin-bottom: $gutter/2;
}

#leadershipTeam {

	padding-top: $gutter;
	margin-bottom: $gutter;
	background-color: white;

	.row {
		margin: $gutter*1.5 0;
	}

	img {
		max-width: 100%;
		max-height: 350px;
		text-align: center;
		margin: auto;
		display: block;
	}

	p.small	{
		margin-bottom: $gutter/2;
	}

	h2 {
		margin-bottom: $gutter;
	}

	.leadershipContinued {

		text-align: center;
		margin-top: -$gutter/2;

		h5 {
			margin-bottom: $gutter/3;
			color: $primary;
		}

		.hover-img {
		  background-color: $primary;
		  color: $white !important;
		  display: inline-block;
		  overflow: hidden;
		  position: relative;
		  text-align: center;
		  margin-bottom: $gutter/2;
		  max-width: 270px;
		  max-height: 350px;
		}

		.hover-img * {
		  box-sizing: border-box;
		  transition: all 0.45s ease;
		}

		.hover-img img {
		  vertical-align: top;
		  max-width: 100%;
		  backface-visibility: hidden;
		}

		.hover-img figcaption {
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  align-items: center;
		  z-index: 1;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;
		  opacity: 0;
		  z-index: 2;
		  transition-delay: 0.1s;
		  padding: 0 15px;

		  p.small {
		  	color: $white;
		  	line-height: 1.25rem;
		  }

		}

		.hover-img:hover:before,
		.hover-img:hover:after {
		  transform: scale(1);
		  opacity: 1;
		}

		.hover-img:hover > img {
		  opacity: .2;
		}

		.hover-img:hover figcaption {
		  opacity: 1;
		}


	}

}

#goodCompany {

	padding: $gutter;

	h5 {
		text-align: left;
		color: $primary !important;
	}

	text-align: center;

	.container {

		.row {

			&.partnerLogoRow {
				
				padding: 0;
				margin-bottom: $gutter/2;

			}

		}

		a {
			font-weight: bold;
			color: $primary;

			&:hover {
				color: $primaryDark;
				text-decoration: none;
			}
		}
	}

}

#patientPaymentReportOne {

	background-color: $primary;

	.left {
		background-color: $primary;

		h2 {
			color: $white;
			text-shadow: $textShadow;
		}
	}

	.right {
		background: linear-gradient(90deg, $primary 0%, $primary 100%);
	}

}

// Announcement Section

#newsAnnouncement {

	padding: $gutter/2;
	text-align: center;
	margin: 0px auto;
	background: $white;
	text-decoration: none;

	p {
		padding: 0;
		margin: 0;
		color: $primary;
		font-size: 14px;
		font-weight: $heavy;
		background-color: $primaryTransparent;
		border-radius: 28px;
		box-shadow: $boxShadow;
		display: inline-block;
		padding: $gutter/3 $gutter/2;
	}

	a {
		font-weight: $heavy;
		color: $primary;

		&:hover {
			color: $primaryDark;

			p {
				box-shadow: $boxShadowBig;
				color: $primaryDark;
				transition: $transition;
			}

		}

	}

}