blockquote {
  position: relative;
  margin: $gutter auto;
  width: 100%;
  font-size: 32px;
  line-height: 48px;
  padding-left: 40px;
  border-left: 5px solid $secondaryDark;
  font-family: $heading;
  font-weight: $regular;
  color: $secondary !important;
}

blockquote span {
  display: block;
  text-align: right;
  font-size: 24px;
  line-height: 40px;
  padding-right: 40px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: $body;
  color: rgba(120, 176, 182, 0.75) !important;
  font-weight: $light;
}

#postHeroImage {

  min-height: 400px;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

}

#post {

  width: 90%;
  background-color: white;
  box-shadow: $boxShadow;
  padding: $gutter;
  margin-top: -$gutter*6;
  border-radius: $borderRadius;
  z-index: auto;
  position: relative;

  p {

    strong {
      color: $primary;
      font-weight: $heavy;
    }

    &.small {
      color: $grey;
    }

  }

  .featuredImg {
    max-width: 100%;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 90%;
  }

  ul, ol {
    list-style-position: outside;

    li {
      font-size: 1.25rem;
      font-family: $body;
      font-weight: $light;
      color: $grey;
      line-height: 1.5rem;
      margin-bottom: $gutter;
      margin-left: $gutter;

      span {
        font-weight: $heavy;
      }
    }

  }

}

#footerForm {

  padding-bottom: $gutter;

  hr {
    margin-top: -$gutter/2;
  }

  img {
    max-width: 100%;
  }

}

#endOfBlogForm {

  h4 {
      color: $primary;
  }

}









