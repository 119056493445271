// About Page

#aboutHero {
	background: url(../img/about/hero.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter*5 0;
	margin-bottom: $gutter;

	h1, p {
		color: $white;
		text-shadow: $textShadow;
	}

	@include media-breakpoint-down(sm) {
		padding: $gutter 0;
	}

}

#aboutValues {

	text-align: center;

	h5 {
		color: $primaryDark;
	}

	.valuesIcons {
		width: 125px;
		margin: 0px auto;
		margin-bottom: $gutter/2;
	}

}

.joinTeamCTA {

	text-align: center;
	margin-bottom: $gutter*1.5;

	h3, .sButton {
		display: inline;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	h3 {
		padding-right: $gutter;
	}

}

// Security and Compliance Page

#securityAndComplianceHero {
	background: url(../img/about/securityHero.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter*5 0;
	margin-bottom: $gutter;

	h1 {
		color: $white;
	}

}

#certifications {

	.col-3 {
		img {
			max-width: 75%;
		}
	}

	h4 {
		color: $primary !important;
	}

}

// Careers Page

#careersHero {
	background: url(../img/about/hero.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter*2 0;
	margin-bottom: $gutter;

	h1, p {
		color: $white;
		text-shadow: $textShadow;
	}

	@include media-breakpoint-down(sm) {
		padding: $gutter 0;
	}

}

// Jazz HR Styling

#resumator-wrapper {

	margin-bottom: $gutter;

	.resumator-department-heading {

		.resumator-department-name {

			font-family: $heading;
			font-weight: $regular;
			color: $secondary;
			background: none;

		}

	}

	.resumator-job-title {

		font-family: $heading;
		font-weight: $regular;
		font-size: 1.4rem;
		line-height: 2rem;
		color: $primary;

		@include media-breakpoint-down(md) {
			font-size: 1.4rem;
			line-height: 2rem;
		}

	}

	.resumator-job-info, .resumator-job-info-details {

		font-size: 1rem;
		font-family: $body;
		font-weight: $light;
		color: $grey;
		line-height: 2rem;
		margin-bottom: $gutter/4;
		background: none;

		@include media-breakpoint-down(md) {
			font-size: 1;
			line-height: 1.5rem;
		}

		span {
			background: none;
		}

	}

	.resumator-job-link {
		color: $primaryDark;
	}

	.resumator-job-description-text, .resumator-jobs-text {

		p {
			font-size: 1rem;
			font-family: $body;
			font-weight: $light;
			color: $grey;
			line-height: 1.5rem;

			@include media-breakpoint-down(md) {
				font-size: 1.25rem;
				line-height: 1.5rem;
			}

			span {
				font-weight: $heavy;
			}

			&.small {
				font-size: 1rem;
				font-weight: $light;
			}

			&.extraSmall {
				font-size: .75rem;
			}
		}
		
		span {
			background: none;
		}

		ul {
			li {
				font-size: 1rem;
				font-family: $body;
				font-weight: $light;
				color: $grey;
				line-height: 1.5rem;

				@include media-breakpoint-down(md) {
					font-size: 1.25rem;
					line-height: 1.5rem;
				}
			}
		}

	}

	.resumator-buttons {

		a[type="button"] {
			display: none;
		}

		input[type="button"] {

			text-decoration: none;
			color: $white !important;
			border-radius: 35px;
			background-color: $primary;
			padding: $gutter/5 $gutter/2;
			box-shadow: $boxShadowBig;
			transition: $transition;

			&:hover {
				background-color: $primaryDark;
			}

			&:hover {
				text-decoration: none;
			}

			&.disabled {
				opacity: .25;
			}

		}

	}

}

#resumator-logo {
	display: none;
}

#openPositions {

	background-color: white;
	padding: $gutter 0;
	margin-bottom: -$gutter;

	.applyButton {
		text-align: center;
		padding: $gutter 0;
	}

}

// Media Kit











