// Colors of All Shapes and Size

$black: #231F20;
$grey: #3E3C44;
$white: #F8F8F9;
$whiteTransparent: rgba(248, 248, 249, 0.45);

$primary: #31334C;
$primaryDark: #111433;
$primaryLight: #A6E9F1;
$primaryTransparent: rgba(166, 233, 241, 0.2);

$secondary: #DA8061;
$secondaryDark: #903718;
$secondaryLight: #F0D9CB;
$secondaryTransparent: rgba(218, 128, 97, 0.25);

// Spacing

$gutter: 35px;

// Random

$transition: all .25s ease;
$boxShadow: 0 4px 8px 0 rgba(0,0,0,.16);
$boxShadowBig: 2px 6px 8px 2px rgba(0,0,0,.15);
$borderRadius: 7px;

/* Forms */
$select-background: #F5F5F5;
$form-controls-border: #929497;