#homeHero {

	position: relative;
	padding: $gutter 0;
	display: block;
	text-align: center;
	background-color: $white;
	height: auto;
	width: 100%;
	overflow: hidden;

	// Mobile Background Image Where Video is Hidden

	background: url(../img/home/webHeroVideo.jpg) ; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	// Video CSS for Small Desktops or Higher

	video {
		display: none;
		position: absolute;
		margin-top: $gutter*3;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		max-width: 100%;
		z-index: 0;
		-ms-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}

	// Responsive Styling to Display Video and Resize Hero Padding

	@include media-breakpoint-up(lg) {

		video {
			display: block;
		}

		padding: $gutter*2 0;

	}

	h1, p {
		color: $primary !important;
		margin-bottom: $gutter*2;
	}

	p {
		font-weight: $regular;
		color: $primaryDark !important;
	}

	#homeHeroContent {

		p {
			margin: $gutter 0;
		}

		.sButtonTransparent {
			color: $primary !important;

			&:hover {
				color: $white !important;
			}

		}

		.sButton {
			text-shadow: none;
			margin-right: $gutter/2;
			margin-left: $gutter/2;
		}

	}

}