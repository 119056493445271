// Centering Twitter Embeds

.twitter-tweet {
	margin: 0px auto;
	text-align: center;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-top: $gutter/2;
	margin-bottom: $gutter/2;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}