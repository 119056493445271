.navbar {

	z-index: 10;
	background-color: $white !important;
	font-family: $body;
	font-weight: $regular;
	font-size: .85rem;
	padding: 20px 0px;
	box-shadow: $boxShadow;

	@include media-breakpoint-down(md) {
		padding: 20px $gutter;
	}
	
	.navbar-brand  {
		
		img {
			height: 25px;
		}

	}
	
	// Animating Top-Level Links to Pulse
	.nav-link:hover {
		color: $primaryDark !important;
	}
	
	a {
		color: $black !important;
		transition: $transition;
		font-weight: $regular;
	}	

	li {
		padding-right: $gutter;
		margin-bottom: 0;

			&:last-child {
			padding-right: 0px;
			}

	}

	// Adding Border to top of dropdown menus.

	a {
		transition: $transition;
	}

	#solutions, #contentAndResources, #aboutUs {
		.dropdown-menu {
			font-size: .85rem;
			border-top: 7px solid $primaryLight;
			background-color: $primary;
			transition: $transition;

			a {
				color: $white !important;
				transition: $transition;
				margin: $gutter/3 0;
				position: relative;
				text-decoration: none;

				// Animating Underline

				&:before {
					content: "";
					position: absolute;
					margin-left: $gutter/1.5;
					width: 33%;
					height: 2px;
					bottom: 0;
					left: 0;
					background-color: $white;
					visibility: hidden;
					-webkit-transform: scaleX(0);
					transform: scaleX(0);
					-webkit-transition: all 0.3s ease-in-out 0s;
					transition: all 0.3s ease-in-out 0s;
				}

				&:hover:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
				}
			}
		}

		.dropdown-item:hover {
			background-color: $primary;
			color: $white !important;
		}
	}

	// Removing Dropdown Arrow and Transition from Click > Hover
	.dropdown-toggle::after {
	    display:none;
	}

	.dropdown:hover>.dropdown-menu {
	  display: block;
	}

	// Demo Button Style
	#demo, #login {

		color: $white !important;

		@include media-breakpoint-down(md) {
			display: none;
		}

	}

}

// No Navigation Header

#noNavigationHeader {
	background-color: $white;
	padding: $gutter/2 0;
	box-shadow: $boxShadow;
	text-align: center;

	img {
		max-height: 25px;
	}

}