.terms {

	padding: $gutter 0;

	ol, ul, p {
		padding-left: $gutter;

		li {
			margin-bottom: $gutter/2;
		}

		ol, ul, p {
			padding-top: $gutter/2;
		}
		
	}

	ol, ul {
		padding-left: $gutter*2;
	}

	table {
		margin: 0 0 $gutter $gutter;
		border: 3px solid $primaryTransparent;
		background-color: white;

		th {
			background-color: $primary;
			color: $white;
			text-align: center;
		}

		th, td {
			padding: $gutter/2;
			border: 3px solid $primary;

			h5, h6, p {
				padding-bottom: 0;
				padding-left: 0;
				margin-bottom: 0;
				color: $primary;
			}

		}

		ul {
			padding-left: inherit;
		}

	}

}

// GDPR/Cookie Settings Styling

#hs-eu-cookie-confirmation {

	background-color: $primary !important;

	#hs-eu-cookie-confirmation-inner {

		background-color: $primary !important;
		max-width: 100% !important;

		#hs-eu-policy-wording {
			
			p {
				color: $white !important;

				a {
					color: $primaryLight;
				}
			}

		}

		#hs-eu-cookie-disclaimer {

			color: $white !important;

		}

		#hs-en-cookie-confirmation-buttons-area {

			a#hs-eu-cookie-settings-button {
				color: $white !important;
			}

			#hs-eu-cookie-confirmation-button-group {

				#hs-eu-confirmation-button {
					background-color: $primaryLight !important;
					color: $primary !important;
					border-radius: $borderRadius !important;
				}

				#hs-eu-decline-button {
					color: $white !important;
				}

			}

		}

	}

}

#hs-modal-content {

	#hs-modal-header {

		#hs-modal-header-container {

			#hs-modal-close-button {
				background: none !important;
			}

		}

	}

	#hs-modal-body {

		#hs-modal-body-container {

			.hs-category-label {

				min-height: 75px !important;

				span {
					background: none !important;
					color: $primary !important;
					padding: $gutter 0 !important;
				}

			}

			.hs-category-row {

				#hs-modal-introduction-description, #hs-category-necessary-description, #hs-category-analytics-description, #hs-category-advertisement-description, #hs-category-functionality-description {

					.hs-category-description-header, .hs-category-necessary-header, .hs-category-analytics-header, .hs-category-description-header, .hs-category-advertisement-header, .hs-category-functionality-header {

						h3 {
							font-family: $heading !important;
							font-weight: $regular !important;
						}

					}

					.hs-category-description-text {

						p {
							font-family: $body !important;
							font-weight: $light !important;
							color: $grey !important;
							font-size: 1rem !important;
							line-height: 1.5rem !important;
						}

					}

				}

			}

		}

	}

}









