@import 'bootstrap/bootstrap';
@import 'custom/includes/reset';
@import 'custom/includes/typography';
@import 'custom/includes/elements';
@import 'custom/includes/header';
@import 'custom/includes/posts';
@import 'custom/includes/pagination';
@import 'custom/includes/post';
@import 'custom/includes/archive';
@import 'custom/includes/home';
@import 'custom/includes/solutions';
@import 'custom/includes/footer';
@import 'custom/includes/terms';
@import 'custom/includes/content-and-resources';
@import 'custom/includes/about';
@import 'custom/includes/embeds';
@import 'custom/includes/contactForms';
@import 'custom/includes/thank-you-pages';
@import 'custom/includes/news';
@import 'custom/includes/includes';
@import 'custom/includes/heros';
@import 'custom/includes/404';
@import 'custom/includes/landing';
@import 'custom/includes/integrations';

body,html {
	color: $grey;
	background-color: $white;
	margin: 0;
	scroll-behavior: smooth;
}

a {
	transition: $transition;
	color: $primaryDark;
	font-weight: $heavy;

	&:hover {
		color: $primary;
	}
}

img {
	pointer-events: none;
}

hr {
	padding-bottom: $gutter;
	width: 75%;
	margin: 0px auto;
	text-align: center;
}

ul, ol {

	padding-left: $gutter;
	
	li {
		margin-bottom: $gutter/2;
	}

}

// Text Selection

::selection {
  background: $primaryTransparent; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $primaryTransparent; /* Gecko Browsers */
}