// Hubspot Form Style Updates

form {

	.hs-error-msg {
		display: block;
	}

	.hs_recaptcha {
		margin-bottom: $gutter;
	}

	ul.hs-error-msgs {

		padding-left: 0;

		li {

			list-style: none;

			label {
				color: $secondary;
				font-weight: bold;
				line-height: 1.5rem;
			}

		}

	}

}

fieldset.form-columns-2 {

	max-width: 100% !important;

}

.formDisclaimer {

	margin-top: $gutter/2;

}

// Standard Form Styles

input, textarea, select {
	border: none;
	box-shadow: $boxShadow;
	padding: $gutter/2 $gutter/2;
	border-radius: $borderRadius;
	width: 95%;
	margin-bottom: $gutter;
	color: $grey;

	&:focus {
		box-shadow: $boxShadowBig;
		outline: none;
	}

}

select {
	width: 90.5% !important;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: red transparent transparent transparent;
}

textarea {
	height: $gutter*3;
}

input[type="submit"] {
	text-decoration: none;
	color: $white !important;
	border-radius: 35px;
	background-color: $secondary;
	padding: $gutter/2 $gutter;
	box-shadow: $boxShadowBig;
	transition: $transition;
	width: auto;
	margin: 0px auto;
	border: none !important;

	&:hover {
		background-color: $primary;
		text-decoration: none;
		border: none !important;
	}

}

// /contact/support/

#supportHero {
	
	padding-top: $gutter*2;

	select#type {
		display: none;
	}

	input[type="checkbox"] {
		box-shadow: none;
		width: auto;
		margin-bottom: 0;
		vertical-align: middle;
		margin-right: $gutter/2;
	}

}

// /contact/request-demo

#requestDemo {

	padding-top: $gutter;
	background: linear-gradient(180deg, $primary 0%, $white 100%);

	h2 {
		color: $white;

		span {
			color: $white !important;
		}

	}

	p {
		color: $white;

		span {
			color: $white !important;
		}
		
	}

	.legal-consent-container {
		p, ul, li, label {
			color: $primaryDark !important;
		}
	}

	.row {
		display: block;
	}

	span {
		color: $primary;
	}

	form {

		input[type="text"], select {
			display: inline;
		}

		input#firstname, input#email, input#jobtitle {
			margin-right: $gutter;
		}

		@include media-breakpoint-down(md) {

			input, select {
				width: 95%;
				display: inline-block;
				margin-right: 0;				
			}
		}
	}

	hr.preCopySection {
		border: 0;
		border-bottom: 3px solid $secondary;
		width: 25%;
		text-align: left;
		margin-left: 0;
		margin-top: 0;
		margin-bottom: $gutter/2;
	}

	img {
		max-width: 100%;
	}

}

#requestDemo > .row:nth-of-type(n+2) {
	background-color: white;
	padding: $gutter;
	border-radius: $borderRadius;
	box-shadow: $boxShadow;
}

// /content-and-resources/downloads

#contentDownloadsGoodCompany {

	p.small {
		margin-bottom: $gutter/2;
		padding-bottom: 0;
	}

	img {
		margin-bottom: $gutter/2;
	}
	
}

// Styling for GDPR Consent Boxes

div.legal-consent-container {

	.hs-richtext:nth-of-type(n+2) {
	    display: none;
	}

	p {
		font-size: .75rem;
		line-height: 1rem;
		font-weight: $light;
		margin-bottom: $gutter/2;
	}

	.inputs-list {
		padding-left: 0;
	}

	label.hs-form-booleancheckbox-display {
		display: flex;
		align-items: center;
	}

	li.hs-form-booleancheckbox {

		margin-bottom: 0px !important;
		margin-left: 0px !important;
		list-style: none;

		input.hs-input[type="checkbox"] {
			box-shadow: none;
			width: auto;
			margin-bottom: 0;
			vertical-align: middle;
		}

		label {

			span {
				background: none;
				font-size: 1rem;
				font-weight: $light;
			}

		}

	}

	ul.hs-error-msgs {
		li {
			label.hs-error-msg {
				font-size: 1rem;
				font-weight: $light;
				color: $secondary;
				margin: 0;
				padding: 0;
			}
		}
	}

}