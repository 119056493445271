// Cerner Integration Content

#cernerPreheader {
	background-color: #949CA1;
	margin: 0px auto;
	text-align: center;

	p.extrasmall {
		text-align: center;
	}

}

.container-fluid#cernerAnnouncementSection {

	background-color: $secondaryLight;
	padding: $gutter/3 0;
	display: block;

	.container {

		.row {

			display: block;

			p.small {
				padding: 0;
				margin: 0;
				text-align: center;
			}

		}

	}

}

#lighthouseHero.CernerHero {

	background-color: $primaryDark;
	
	#contentDownloadFormContainer {

		margin-bottom: -150px;

		@include media-breakpoint-down(sm) {
			margin-top: $gutter*2 !important;
		}

		.legal-consent-container {

			padding: $gutter/2 0;

			p, ul, li, label {
				color: $primaryDark !important;
				font-size: .75rem;
				line-height: 1rem;
			}

		}

	}

}

#CernerBullets {
	padding-top: $gutter*4;
	text-align: center;
	background-color: $primaryTransparent;
	color: $primaryDark;

	h4 {
		color: $primary;
	}

	img {
		margin-bottom: 0;
		max-width: 100px;
	}
}

#CernerValue {

	background: $white;
	background: linear-gradient(180deg, $primaryTransparent 0%, $white 100%);
	padding-top: $gutter*3;
	margin-bottom: $gutter*3;

	.image {

		img {
			overflow: hidden;
			height: 100%;
			margin-left: -$gutter*8;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}

	}

	.text {

		display: flex;
		justify-content: center;
		flex-direction: column;
		height: auto;

		h6 {
			color: $primary;
			margin-bottom: $gutter/2;
		}

		p.small {
			padding-top: 0;
		}

		img {

			display: none;

			@include media-breakpoint-down(md) {
				display: block;
				margin-bottom: $gutter;
				max-width: 100%;
			}

		}

	}

}

.CernerFooterForm {

	margin-bottom: 0 !important;

}

#CernerDisclaimer, #integrationsDisclaimer {

	padding-top: $gutter/2;
	padding-bottom: $gutter;

	p.small {
		text-align: center;
		opacity: .75;
		padding: 0;
		margin: 0;
	}

}

// Straight-Through Processing Integration Page

#lighthouseHero.STPHero {

	background: $primary;
	background: linear-gradient(180deg, $primary 0%, $primaryTransparent 100%);
	
	#contentDownloadFormContainer {

		margin-bottom: -150px;

		@include media-breakpoint-down(sm) {
			margin-top: $gutter !important;
		}

	}

}

#STPBullets {
	padding-top: $gutter*4;
	text-align: center;
	background-color: $primaryTransparent;
	color: $secondaryDark;

	h4 {
		color: $primaryDark;
	}

	img {
		margin-bottom: 0;
		max-width: 100px;
	}
}

#STPValue {

	background: $white;
	background: linear-gradient(180deg, $primaryTransparent 0%, $white 100%);
	padding-top: $gutter*3;
	margin-bottom: $gutter*3;

	h4 {
		text-align: center;
		color: $primary;
	}

	.image {

		img {
			overflow: hidden;
			height: 100%;
			margin-left: -$gutter*8;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}

	}

	.text {

		display: flex;
		justify-content: center;
		flex-direction: column;
		height: auto;

		h6 {
			color: $primary;
			margin-bottom: $gutter/2;
		}

		p.small {
			padding-top: 0;
		}

		img {

			display: none;

			@include media-breakpoint-down(md) {
				display: block;
				margin-bottom: $gutter;
				max-width: 100%;
			}

		}

	}

}