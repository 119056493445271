hr.salucro {
	margin: 0 !important;
	padding: 0 !important;
	margin-bottom: $gutter/2 !important;
	width: 15%;
	border: 2px solid $secondary;
}

.sButton {

	text-decoration: none;
	color: $white !important;
	border-radius: 35px;
	background-color: $primary;
	padding: $gutter/5 $gutter/2;
	box-shadow: $boxShadowBig;
	transition: $transition;

	&:hover {
		background-color: $primaryDark;
	}

	&:hover {
		text-decoration: none;
	}

	&.disabled {
		opacity: .25;
	}

	&.sButtonPrimary {
		background-color: $primary;

		&:hover {
			background-color: $primaryDark;
		}
	}

	&.sButtonPrimaryLight {
		background-color: $primaryLight;
		color: $primaryDark !important;

		&:hover {
			background-color: $primary;
			color: $white !important;
		}
	}

	&.sButtonSecondary {
		background-color: $secondary;

		&:hover {
			background-color: $secondaryDark;
		}
	}

}

.sButtonNewerPosts, .sButtonOlderPosts {

	a {
		color: $white !important;

		&:hover {
			text-decoration: none;
			color: $primary !important;
		}
	}

	text-decoration: none;
	color: $white !important;
	border-radius: 35px;
	border: none;
	background-color: $primaryDark;
	padding: $gutter/3 $gutter;
	box-shadow: $boxShadowBig;
	transition: $transition;

	&:hover {
		background-color: $primaryLight;
		color: $primary;
	}

	&:hover {
		text-decoration: none;
	}

}

.sButtonTransparent {
	text-decoration: none;
	color: $white !important;
	border-radius: 35px;
	border: 3px solid $primary;
	background-color: none;
	padding: $gutter/5 $gutter/2;
	transition: $transition;
	max-width: 75%;
	margin: 0px auto;

	&:hover {
		background-color: $primaryDark;
		border-color: $primaryDark;
	}

	&:hover {
		text-decoration: none;
	}

}

.sButtonTransparentWhiteBG {
	color: $primary !important;

	&:hover {
		color: $white !important;
	}
}