// Reports and Whitepapers

#reportsAndWhitepapersHero {
	background: url(../img/content-and-resources/contentDownloadsHero.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter*3 0;
	margin-bottom: $gutter;

	h1 {
		color: $white;
		text-shadow: $textShadow;
	}

}

.tiles {

	margin: 0px auto;
	text-align: center;

	.tile {
		width: 95%;
		background-color: white;
		box-shadow: $boxShadow;
		border-radius: 7px;
		padding-bottom: $gutter;
		margin-bottom: $gutter;
		min-height: 400px;

		&:hover {
			box-shadow: $boxShadowBig;
		}

		img {
			max-width: 100%;
			margin-bottom: $gutter/2;
			border-radius: 7px 7px 0px 0px;
		}

		h4, h5 {
			color: $white;
			font-weight: $heavy;
			margin-bottom: 0;
		}

		h5 {
			margin-bottom: $gutter;
		}

		p.small {
			color: $white;
		}

		&.tileOne {
			background: $primary;
			background: linear-gradient(40deg, $primary 0%, $primaryLight 100%);
			opacity: 0.95;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}

		&.tileTwo {
			background: $primaryLight;
			background: linear-gradient(40deg, $primaryLight 0%, $primaryDark 100%);
			opacity: 0.95;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}

		&.tileThree {
			background: $primaryDark;
			background: linear-gradient(40deg, $primaryDark 0%, $secondary 100%);
			opacity: 0.95;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}

		&.tileFour {
			background: $secondary;
			background: linear-gradient(40deg, $secondary 0%, $secondaryDark 100%);
			opacity: 0.95;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}

		&.tileFive {
			background: $secondaryDark;
			background: linear-gradient(40deg, $secondaryDark 0%, $primary 100%);
			opacity: 0.95;
			transition: $transition;

			&:hover {
				opacity: 1;
			}
		}

	}

}

// Content Download Landing Page

#pptrReportPreview {

	.highLevelStats {

		text-align: center;

		img {
			margin-bottom: $gutter;
			max-width: 200px;
		}

	}

}

#contentDownloadFormContainer {

	background-color: white;
	padding: $gutter/2 $gutter/2 0 $gutter/2;
	border-radius: $borderRadius;
	margin-bottom: $gutter*1.5;
	box-shadow: $boxShadow;
}

// Webinars

.webinarContainer {

	padding: $gutter 0;

	.embed-container {
		margin-bottom: $gutter;
	}

}

#lighthouseHero {

	background-color: $primary;

	.legal-consent-container {
		p, ul, li, label {
			color: $primaryDark !important;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0 $gutter;
	}

	&.providerReport24 {
		
		background: $secondaryLight;
		background: linear-gradient(180deg, $secondaryLight 0%, $white 100%);

		.container {
			padding-top: $gutter;
			padding-bottom: 0;
		}

		h1 {
			color: $primary;
		}

		p {
			color: $grey;
		}

	}

	.container {
		padding-top: $gutter*2;
		padding-bottom: $gutter*2;
	}

	p.small {
		margin-bottom: $gutter/4;
		opacity: .85;
	}

	h1, p {
		color: $white;
	}

	h1 {
		font-size: 2.5rem;
		line-height: 2.75rem;

		@include media-breakpoint-down(md) {
			font-size: 1.6rem;
			line-height: 2rem;
		}

	}

	img {
		max-width: 100%;
	}

	#contentDownloadFormContainer {

		box-shadow: $boxShadowBig;

		h4 {
			color: $primary;
			font-size: 1.5rem;
			line-height: 1.75rem;
			margin-bottom: $gutter/4;

			@include media-breakpoint-down(md) {
				font-size: 1.2rem;
				line-height: 2rem;
			}

		}

		input, select {
			margin-bottom: $gutter/4;
		}

		div.hs_recaptcha {
			margin-top: $gutter/2;
		}

		p.extraSmall {
			color: $grey;
		}
	}

}

#lighthousePreview {

	padding: $gutter 0;

	.reportQuote {
		text-align: center;

		p.small {
			margin-bottom: $gutter/4;
			opacity: .85;
			text-transform: uppercase;
		}

	}

}









