
#solutionsHero {

	padding: $gutter 0;

	h1 {
		color: $primary;
	}

}

#productIntroductions {

	padding: 0 $gutter;
	overflow: none;

	.productIntroCol {

		background-color: white;
		box-shadow: $boxShadow;
		border-radius: $borderRadius;
		padding: $gutter/2 $gutter/3;
		text-align: center;
		margin-bottom: $gutter;

		h5 {
			color: $primary;
		}

		img {
			max-width: 70%;
			text-align: center;
			margin: 0px;
		}

	}

	.productPhotoPairing {

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: $gutter;
		
		img {
			max-width: 100%;
		}

	}

}

#patientPaymentTypes {

	h4 {
		font-weight: $heavy;
		margin-top: $gutter/2;
	}

	img {
		max-width: 100%;
	}

}

#providerSolutions {

	margin-top: $gutter*2;

	#streamlinedWorkflows {

		padding-top: $gutter;

		h4 {
			margin-top: $gutter;
		}

		img {
			max-width: 100%;
		}

	}

}

#seamlessIntegrations {

	#EHRIntegrationsList {

		.row {
			margin-bottom: $gutter*2;
		}

		img {
			max-width: 100%;
		}

	}

}









