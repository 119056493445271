// News and Press Page

#pressHero {
	margin-top: $gutter*2;
	margin-bottom: $gutter;
}

#featuredPress {

	h5 {
		padding: 0 $gutter/2;
	}

	.tile {
		min-height: 498.05px;

		@include media-breakpoint-down(sm) {
			min-height: 0px;
		}

	}

}

#pressFiltering {

	margin-bottom: $gutter;
	text-align: center;
	transition: $transition;

	@include media-breakpoint-down(md) {
		display: none;
	}

	p.sButton {
		text-decoration: none;
		color: $white !important;
		border-radius: 35px;
		background-color: $primary;
		padding: $gutter/5 $gutter/2;
		box-shadow: $boxShadowBig;
		transition: $transition;
		display: inline-block;
		font-size: 1rem;
		margin: 0px $gutter/2;
		font-weight: $heavy;
		cursor: pointer;

		&:hover {
			background-color: $primaryDark;
		}

		&:hover {
			text-decoration: none;
		}

		&.disabled {
			opacity: .25;
		}

		&.sButtonPrimary {
			background-color: $primary;

			&:hover {
				background-color: $primaryDark;
			}
		}

		&.sButtonSecondary {
			background-color: $secondary;

			&:hover {
				background-color: $secondaryDark;
			}

		}

	}

}

#pressListings {

	ul {

		margin: 0;
		padding: 0;
		
		li {
			margin-top: 0 !important;
			display: inline-block;
			list-style: none;
			background-color: white;
			border-radius: $borderRadius;
			box-shadow: $boxShadow;
			text-align: left;
			padding: $gutter/2 $gutter $gutter $gutter;
			width: 100%;

			h5 {
				color: $primary;
				padding: 0;
				margin: 0;
				padding-bottom: $gutter/3;
			}

			p.small {
				color: $grey;
				padding: 0;
				margin: 0;
				margin-bottom: $gutter/2;
				opacity: 0.6;
			}

			p.pressSource {
				margin-bottom: $gutter/1.25;
			}

			&#pressArticle, &#pressRelease, &#pressInterview {

				transition: $transition;

				&.hidden {
					display: none;
				}
			}

		}

	}

}