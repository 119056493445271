#preFooterCTA {
	margin-top: $gutter;
	padding-top: $gutter*1.5;
	margin-bottom: $gutter/2;

	@include media-breakpoint-down(md) {
		margin-top: 0;
	}

	h4, p {
		color: $white;
	}
	
	p {
		margin: $gutter auto;
	}

	form {

		@include media-breakpoint-down(sm) {
			input {
				display: block;
				margin-right: 0;
				margin-bottom: $gutter;
				width: 100%;
			}

			input[type="email"] {
				width: 100% !important;
			}
		}

		input[type="email"] {
			border: none;
			box-shadow: $boxShadow;
			padding: $gutter/2 0;
			padding-left: $gutter;
			width: 80%;
			border-radius: $borderRadius;
			margin-right: $gutter;

			&:focus {
				box-shadow: $boxShadowBig;
				outline: none;
			}

		}

		input[type="submit"] {
			text-decoration: none;
			color: $white !important;
			border-radius: 35px;
			background-color: $primaryTransparent;
			padding: $gutter/2 $gutter;
			box-shadow: $boxShadowBig;
			transition: $transition;
			max-width: 50%;
			margin: 0px auto;
			border: none !important;

			&:hover {
				background-color: $primaryLight;
				color: $black !important;
				text-decoration: none;
				border: none !important;
				cursor: pointer;
			}
		}

	}

}

footer {

	margin-top: $gutter;
	background: $primary;
	background: linear-gradient(180deg, $primary 33%, $primaryDark 100%);

	.footerLogo {
		margin-top: $gutter;
		margin-bottom: $gutter/4;
		max-width: 150px;
	}

	.container-fluid {
		padding: 0px;
		text-align: center;

		.row {
			margin: 0;
			padding: 0;
		}
	}

	#footerTop {

		margin: 0px auto;
		padding: $gutter $gutter/2;

		p {
			color: $whiteTransparent;
			margin-bottom: $gutter;
		}

		p.small {
			color: $white;
			text-align: left;
			line-height: 1.5rem;
			margin-bottom: $gutter/3;
			text-transform: uppercase;

			a {
				color: $white;
				font-weight: $light;

				&:hover {
					text-decoration: none;
				}

			}
		}
	}

	#footerBottom {

		padding-bottom: $gutter*1.5;
		margin: 0px;

		p {
			color: $whiteTransparent;
		}

		a {
			color: $whiteTransparent;

			&:hover {
				color: $white;
			}
			
		}

		.fa-linkedin-in {
			margin: 0 $gutter/2;
		}

	}

}

#noNavFooter {

	padding-top: $gutter;
	margin-top: $gutter;
}