@import url("https://use.typekit.net/ipp8idi.css");

// Body and Heading Font Customizations

$body: lato, sans-serif;
$heading: adelle, serif;

// Font Weight Customizations

$light: 300;
$regular: 400;
$heavy: 600;

$textShadow: 1px 1px 1px rgba(0, 0, 0, 0.35);

html, body {
	font-family: $body;
}

h1, h2, h3, h4, h5 {
	font-family: $heading;
	font-weight: $regular;
	margin-bottom: $gutter;

	span {
		font-weight: $heavy;
	}
}

h1 {
	font-size: 3.5rem;
	line-height: 4rem;
	color: $primary;

	@include media-breakpoint-down(md) {
		font-size: 2rem;
		line-height: 2.5rem;
	}

}

h2 {
	font-size: 3rem;
	line-height: 3.25rem;
	color: $primary;

	@include media-breakpoint-down(md) {
		font-size: 1.8rem;
		line-height: 2rem;
	}

}

h3 {
	font-size: 2.5rem;
	line-height: 2.75rem;
	color: $primaryDark;

	@include media-breakpoint-down(md) {
		font-size: 1.6rem;
		line-height: 2rem;
	}
}

h4 {
	font-size: 2rem;
	line-height: 2.25rem;
	color: $secondary;

	@include media-breakpoint-down(md) {
		font-size: 1.4rem;
		line-height: 2rem;
	}

}

h5 {
	font-size: 1.5rem;
	line-height: 1.75rem;
	color: $secondaryDark;

	@include media-breakpoint-down(md) {
		font-size: 1.2rem;
		line-height: 2rem;
	}
}

p {
	font-size: 1.5rem;
	font-family: $body;
	font-weight: $light;
	color: $grey;
	line-height: 2rem;
	margin-bottom: $gutter;

	@include media-breakpoint-down(md) {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	span {
		font-weight: $heavy;
	}

	&.small {
		font-size: 1rem;
		font-weight: $light;
	}

	&.extraSmall {
		font-size: .75rem;
	}

	strong {
		font-weight: $heavy;
	}

}

ul, ol {

	li {
		font-weight: $light;

		strong {
			font-weight: $heavy;
		}
		
	}

}

span {
	background: linear-gradient(transparent 0%, transparent 65%, $primaryTransparent 65%, $primaryTransparent 100%);
}