// 
// Campaign Landing Pages
// 

// GA General

#landingHero {

	padding-top: $gutter;
	padding-bottom: $gutter;
	width: 100%;
	overflow: hidden;

	.heroContent {

		padding-top: $gutter*4;

		@include media-breakpoint-down(sm) {
			padding-top: $gutter/2;
		}

		p.small {
			padding-bottom: 0;
			margin-bottom: $gutter/3;
		}

		h1 {
			padding-top: 0;
			margin-top: 0;
		}

	}

	img {

		max-width: 800px;

		@include media-breakpoint-down(sm) {
			max-height: 450px;
		}

	}

}

#landingSecondaryContent {

	text-align: center;
	margin-bottom: $gutter;

	.highLevelStats {

		img {
			max-width: 80%;

			@include media-breakpoint-down(sm) {
				max-width: 50%;
			}

		}

	}
	
}

#landingForm {

	width: 100%;
	overflow: hidden;
	padding-bottom: $gutter;
	margin-bottom: $gutter;

	img {
		max-width: 800px;

		@include media-breakpoint-down(sm) {
			display: none;
		}

	}

	.landingFormContent {
		background-color: rgba(255, 255, 255, 0.95);
		border-radius: $borderRadius;
		box-shadow: $boxShadow;
		padding: $gutter;
		height: fit-content;
	}

}

// 
// Webinars
// 

// General

#webinarHero {

	background: url(../img/landing/webinar/heroBG.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter 0;
	margin-bottom: $gutter;

	h1, p {
		color: $white;
		text-shadow: $textShadow;
	}

	@include media-breakpoint-down(sm) {
		padding: $gutter 0;
	}

}

#webinarLanding {

	padding: 0 $gutter;

	#webinarLandingContent {

		padding: 0 $gutter*2;

		@include media-breakpoint-down(sm) {
			padding: 0;
		}
	}

	#webinarSpeakerBios {

		padding: 0;

		@include media-breakpoint-down(sm) {
			margin: 0px auto;
			text-align: center;
		}

		img {
			width: 100%;
			max-width: 100%;

			@include media-breakpoint-down(sm) {
				max-width: 50%;
			}
		}

		h4, p {
			color: $primaryDark;
			margin-bottom: $gutter/3;
		}

		hr {
			margin-top: $gutter/2;
		}

	}

	#webinarRegistrationForm {
		background-color: $primary;
		box-shadow: $boxShadow;
		border-radius: $borderRadius;
		padding: $gutter $gutter/2;
		text-align: center;
		max-height: 685px;

		p.extraSmall {
			color: $whiteTransparent;

			a {
				color: $white;
			}

		}

		input[type="submit"]:hover {
			background-color: $secondaryDark;
			cursor: pointer;
		}

	}

}

// 
// HFMA
// 

// HI

#HFMAHIHero {

	background: url(../img/landing/hfma/hi/hero.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: $gutter*3 0;
	margin-bottom: $gutter;

	h1, p {
		color: $white;
		text-shadow: $textShadow;
	}

	@include media-breakpoint-down(sm) {
		padding: $gutter 0;
	}

}