// Video Modal 

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
}

.modal-body {
	position: relative;
	padding: 0px;
}

.close {
	position: absolute;
	right: -30px;
	top: 0;
	z-index: 999;
	font-size: 2rem;
	font-weight: normal;
	color: #fff;
	opacity: 1;
}

#homeSolutionsIntro {

	padding-top: $gutter*1.5;

	h3, p, a {
		text-align: center;
	}

	a.overviewVideoCTA {

		font-weight: bold;
		transition: $transition;
		color: $primary;

		&:hover {
			text-decoration: none;
			color: $primaryDark;
		}

	}

}

#homePageProductPreview {

	padding: 0;
	margin: 0 auto;
	text-align: center;
	padding-bottom: $gutter;

	.row {
		margin-left: 0;
		margin-right: 0;
	}

	.col-md {
		padding: 0;
		margin: 0px auto;

		img {
			width: 100%;
			max-width: 1920px;

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		.largeProductPreview {

			width: 100%;
			max-width: 1920px;
			text-align: center;
			margin: 0px auto;
			padding: 0;
			display: none;

			@include media-breakpoint-up(xl) {
				display: block;
			}
		}

	}

}

#homepageTrustInto {
	background: url(../img/home/trustBackground.png) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.securityImage {
		padding: 0 $gutter;
		text-align: center;

		img {
			// max-width: 70%;
			margin: 0px auto;
		}
	}

}